import { Dialog } from "@material-ui/core";
import {
  Box,
  Button,
  DatePicker,
  Field,
  Form,
  Grid,
  Notification,
  Select,
  TextField,
  Typography,
} from "components";
import dayjs from "dayjs";
import { compose, withAuthorize, withFormik, withHooks } from "enhancers";
import newWithTranslation from "enhancers/withTranslation";
import { get } from "lodash";
import appStore from "stores/appStore";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { gql, Yup } from "utils/helper";
const ResetFilterButton = styled(Box)`
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: ${AppColor["Primary/Primary Text"]};
  :hover {
    cursor: pointer;
  }
`;

const ReportModal = (props: any) => (
  <Dialog open={props.isOpen}>
    <Form>
      <Box>
        <Box display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h3">{"สร้างรายงาน"}</Typography>
        </Box>
        <Grid container spacing={0}>
          <Grid item xs={12} mb={4}>
            <Field
              component={TextField}
              name="reportName"
              label="ชื่อรายงาน"
              placeholder="ชื่อรายงาน"
              fullWidth
              // value={props.values.reportName}
              required
            />
          </Grid>

          <Grid item xs={12} mb={4}>
            <Field
              component={Select}
              name="reportType"
              type="text"
              label="ประเภทรายงาน"
              placeholder="ประเภทรายงาน"
              options={props.reportTypeOptions}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} mb={4}>
            <Field
              component={Select}
              name="department"
              type="text"
              label="ฝ่าย/สังกัด"
              placeholder="สังกัด"
              options={props.departmentOptions}
              fullWidth
              required
              disableClearable={true}
            />
          </Grid>

          <Grid item xs={12} mb={4}>
            <Field
              component={Select}
              name="employee"
              type="text"
              label="พนักงานเจ้าของคำร้อง"
              placeholder="พนักงานเจ้าของคำร้อง"
              options={props.employeeGroupsOptions}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} mb={4}>
            <Field
              component={Select}
              name="claimRequestTo"
              type="text"
              label="เบิกให้"
              placeholder="เบิกให้"
              options={props.claimRequestOptions}
              fullWidth
            />
          </Grid>
          <Grid container spacing={6} mb={4}>
            <Grid item xs={12} sm={6}>
              <Field
                component={Select}
                name="relationWithOwner"
                type="text"
                label="ความสัมพันธ์กับเจ้าของคำร้อง"
                placeholder="ความสัมพันธ์กับเจ้าของคำร้อง"
                options={props.relationWithOwnerOptions}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={Select}
                name="payRound"
                type="text"
                label="รอบการจ่าย"
                placeholder="รอบการจ่าย"
                options={props.payRoundOptions}
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="space-between" mb={4}>
            <Typography variant="h6">
              {"ช่วงวันที่สำหรับเปรียบเทียบข้อมูล"}
            </Typography>
          </Box>
          <Grid container spacing={6} mb={4}>
            <Grid item xs={12} sm={6}>
              <Field
                fast={false}
                component={DatePicker}
                name="startDate"
                label="วันที่สร้างรายการ"
                placeholder="วันที่สร้างรายการ"
                fullWidth
                maxDate={props.values.endDate}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fast={false}
                component={DatePicker}
                name="endDate"
                label="จนถึงวันที่"
                placeholder="จนถึงวันที่"
                fullWidth
                minDate={props.values.startDate}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fast={false}
                component={DatePicker}
                name="startDateCompare"
                label="วันที่สำหรับรายงานเปรียบเทียบตั้งแต่"
                placeholder="วันที่สำหรับรายงานเปรียบเทียบตั้งแต่"
                fullWidth
                maxDate={props.values.endDateCompare}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fast={false}
                component={DatePicker}
                name="endDateCompare"
                label="จนถึงวันที่"
                placeholder="จนถึงวันที่"
                fullWidth
                minDate={props.values.startDateCompare}
                required
              />
            </Grid>
          </Grid>
        </Grid>
        <Box
          style={{ display: "flex", gap: "8px", justifyContent: "flex-end" }}
        >
          <Button variant="outlined" onClick={props.clearFilter}>
            {props.t(".cancelButtonLabel")}
          </Button>
          <Button type="submit">{props.t(".okButtonLabel")}</Button>
        </Box>
      </Box>
    </Form>
  </Dialog>
);
const API = {
  GET_DEPARTMENT: gql`
    query GET_DEPARTMENT {
      getDepartmentOption {
        label
        value
      }
    }
  `,
  GET_REPORTOPTIONS: gql`
    query GET_REPORTOPTIONS($id: String!) {
      getReportTypeOption(input: { id: $id }) {
        label
        value
      }
    }
  `,
  GET_EMPLOYEEOWNSREQUEST: gql`
    query GET_EMPLOYEEOWNSREQUEST($department: String) {
      getEmployeeOwnsRequestOption(input: { department: $department }) {
        label
        value
      }
    }
  `,
  GET_CLAIMREQUESTTO: gql`
    query GET_CLAIMREQUESTTO($employee: String) {
      getClaimRequestTo(input: { employee: $employee }) {
        label
        value
      }
    }
  `,
  GET_PAYROUND: gql`
    query GET_PAYROUND {
      getPayRoundOption {
        label
        value
      }
    }
  `,
  GET_RELATIONWITHOWNER: gql`
    query GET_RELATIONWITHOWNER {
      getrelationWithOwner {
        label
        value
      }
    }
  `,
  GEN_REPORT: gql`
    mutation GEN_REPORT(
      $reportName: String
      $reportType: String
      $startDate: String
      $endDate: String
      $claimRequestTo: String
      $employee: String
      $department: String
      $payRound: String
      $relationWithOwner: String
      $startDateCompare: String
      $endDateCompare: String
      $userId: String
    ) {
      generateReport(
        input: {
          reportName: $reportName
          reportType: $reportType
          startDate: $startDate
          endDate: $endDate
          claimRequestTo: $claimRequestTo
          employee: $employee
          department: $department
          payRound: $payRound
          relationWithOwner: $relationWithOwner
          startDateCompare: $startDateCompare
          endDateCompare: $endDateCompare
          userId: $userId
        }
      )
    }
  `,
};

const enhancer = compose(
  withAuthorize(),
  withFormik({
    mapPropsToValues: () => ({
      reportName: "Report_" + dayjs(new Date()).format("DDMMYYYYHHmm"),
      reportType: "",
      department: "all",
      payRound: "all",
      relationWithOwner: "all",
      claimRequestTo: "",
      startDate: undefined,
      endDate: undefined,
      startDateCompare: undefined,
      endDateCompare: undefined,
    }),
    validationSchema: Yup.object().shape({
      reportName: Yup.string().required(".reportNameWarning").nullable(),
      reportType: Yup.string().required(".reportTypeWarning").nullable(),
      department: Yup.string().required(".reportDepartmentWarning").nullable(),
      relationWithOwner: Yup.string()
        .required(".relationWithOwnerWarning")
        .nullable(),
      startDate: Yup.date().required(".startDateWarning").nullable(),
      endDate: Yup.date().required(".endDateWarning").nullable(),
      startDateCompare: Yup.date()
        .required(".startDateCompareWarning")
        .nullable(),
      endDateCompare: Yup.date().required(".endDateCompareWarning").nullable(),
    }),
  }),
  newWithTranslation({
    prefix: "pages.main.reports.index.modal",
  }),
  withHooks((props: any, hooks: any) => {
    const {
      values,
      setFieldValue,
      isOpen,
      handleCloseModal,
      fetchData,
      t,
      resetForm,
    } = props;
    const {
      useQuery,
      useMemo,
      useEffect,
      useCallback,
      useHandleSubmit,
      useMutation,
    } = hooks;
    const { data: reportTypeOptions } = useQuery(API.GET_REPORTOPTIONS, {
      fetchPolicy: "network-only",
      variables: { id: appStore.state.currentUser.id },
    });
    const { data: department, loading } = useQuery(API.GET_DEPARTMENT, {
      fetchPolicy: "network-only",
    });
    const { data: employeeOptions } = useQuery(API.GET_EMPLOYEEOWNSREQUEST, {
      fetchPolicy: "network-only",
      variables: { department: values.department },
    });
    const { data: claimRequestOptions } = useQuery(API.GET_CLAIMREQUESTTO, {
      fetchPolicy: "network-only",
      variables: { employee: values.employee },
    });
    const { data: payRoundOptions } = useQuery(API.GET_PAYROUND, {
      fetchPolicy: "network-only",
    });
    const { data: relationWithOwnerOptions } = useQuery(
      API.GET_RELATIONWITHOWNER,
      {
        fetchPolicy: "network-only",
      }
    );

    const [genReport] = useMutation(API.GEN_REPORT, {
      onCompleted: () => {
        Notification.success(t(".generating"));
      },
    });

    const departmentOptions = useMemo(() => {
      return [{ label: "ทั้งหมด", value: "all" }].concat(
        department?.getDepartmentOption
      );
    }, [department, loading]);

    useHandleSubmit(async (values: any) => {
      await genReport({
        variables: { ...values, userId: appStore.state.currentUser.id },
      });
      resetForm();
      handleCloseModal();
      fetchData();
    }, []);

    const clearFilter = useCallback(async () => {
      resetForm();
      handleCloseModal();
    }, []);

    useEffect(() => {
      if (values.department) {
        setFieldValue("employee", "");
        setFieldValue("claimRequestTo", "");
      }
    }, [setFieldValue, values.department]);

    return {
      reportTypeOptions: reportTypeOptions?.getReportTypeOption || [],
      benefitTitleOptions: department,
      values,
      clearFilter,
      isOpen,
      departmentOptions,
      payRoundOptions: payRoundOptions?.getPayRoundOption || [],
      claimRequestOptions: claimRequestOptions?.getClaimRequestTo || [],
      relationWithOwnerOptions:
        relationWithOwnerOptions?.getrelationWithOwner || [],
      employeeGroupsOptions:
        employeeOptions?.getEmployeeOwnsRequestOption || [],
    };
  })
);

export default enhancer(ReportModal);
