import { ReactComponent as FilterIcon } from "assets/icon/filter_outline.svg";
import {
  Box,
  Button,
  DatePicker,
  Field,
  Form,
  Grid,
  Typography,
} from "components";
import Select from "components/common/Select";
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import styled from "styled-components";
import { AppColor } from "theme/app-color";

const reportMockRaw = [
  {
    label: "รายงานการเบิกค่ารักษาพยาบาล (รายพนักงาน)",
    value: "MEDICAL_REIMBURSEMENT_PER_EMPLOYEE",
  },
  {
    label: "รายงานการเบิกค่ารักษาพยาบาลตามฝ่าย/สังกัด",
    value: "MEDICAL_REIMBURSEMENT_DEPARTMENT",
  },
  {
    label: "รายงานเงินช่วยเหลือบุตร (รายพนักงาน)",
    value: "SUPPORT_CHILD_PER_EMPLOYEE",
  },
  {
    label: "รายงานเงินช่วยเหลือบุตรที่ยังไม่เกิน 18 ปีบริบูรณ์ (รายพนักงาน)",
    value: "SUPPORT_CHILD_UNDER_18_PER_EMPLOYEE",
  },
  {
    label: "รายงานประวัติการเบิกเงินช่วยเหลือบุตร",
    value: "SUPPORT_CHILD_HISTORY_DISBURSEMENT",
  },
  {
    label: "รายงานการเบิกค่าช่วยเหลือการศึกษาบุตร (รายพนักงาน)",
    value: "EDUCATION_CHILD_PER_EMPLOYEE",
  },
  {
    label: "รายงานการเบิกค่าช่วยเหลือจัดการงานศพ (3 เท่า)",
    value: "COMPENSATION_FUNERAL_DISBURSEMENT_SUPPORT",
  },
  {
    label: "รายงานการเบิกค่าเจ้าภาพงานศพ",
    value: "COMPENSATION_FUNERAL_DISBURSEMENT",
  },
  {
    label: "รายงานการเบิกค่าอุปกรณ์เคารพศพ",
    value: "COMPENSATION_FUNERAL_DISBURSEMENT_EQUIPMENT",
  },
  {
    label: "สรุปการเบิกค่ารักษาพยาบาลตามฝ่าย/สังกัด",
    value: "SUMMARY_MEDICAL_REIMBURSEMENT_DEPARTMENT",
  },
  {
    label: "สรุปการเบิกค่าเล่าเรียนบุตรตามฝ่าย/สังกัด",
    value: "SUMMARY_CHILD_EDUCATION_REIMBURSEMENT_DEPARTMENT",
  },
  {
    label: "สรุปการเบิกเงินช่วยเหลือบุตรตามฝ่าย/สังกัด",
    value: "SUMMARY_CHILD_SUPPORT_REIMBURSEMENT_DEPARTMENT",
  },
  {
    label: "สรุปรายการเบิกทั้งหมด รายครั้ง",
    value: "SUMMARY_ALL_EACH_TIMES",
  },
];
const reportMockRaw2 = [
  {
    label: "รายงานเงินช่วยเหลือบุตร (เพิ่มขึ้น) ตามฝ่าย/สังกัด",
    value: "AFFECT_CHILD_SUPPORT_INCREASE",
  },
  {
    label: "รายงานเงินช่วยเหลือบุตร (ลดลง) ตามฝ่าย/สังกัด",
    value: "AFFECT_CHILD_SUPPORT_REDUCE",
  },
];

const allFilterReportType = [...reportMockRaw, ...reportMockRaw2].map(
  (report) => ({
    label: report.label,
    value: report.label,
  })
);
const stateMock = [
  {
    label: "สำเร็จ",
    value: "complete",
  },
  {
    label: "กำลังสร้าง",
    value: "generating",
  },
  {
    label: "ล้มเหลว",
    value: "failed",
  },
];

const FilterCountNotify = styled(Box)`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ffa500;
  display: flex;
`;

const ReportFilterComponent = ({
  t,
  filterCount,
  handleShowFilter,
  showFilter,
  startDate,
  endDate,
  handleClearFilter,

  resetKey,
}: any) => {
  return (
    <Box>
      <Form>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <FilterIcon />
            <Typography variant="h6" mx={2}>
              {t(".filterTitle")}
            </Typography>
            {filterCount > 0 && (
              <FilterCountNotify>
                <Typography variant="body1">{filterCount}</Typography>
              </FilterCountNotify>
            )}
          </Box>
          <Box display="flex" alignItems="center" onClick={handleShowFilter}>
            <Typography
              variant="body1"
              style={{
                textDecoration: "underline",
                color: AppColor["Primary/Primary Text"],
                cursor: "pointer",
              }}
            >
              {showFilter ? t(".hide") : t(".show")}
            </Typography>
          </Box>
        </Box>
        {showFilter && (
          <Box display="flex" flexDirection="column">
            <Grid mt={6}>
              <Box>
                <Grid container spacing={7} mb={4}>
                  <Grid item xs={3}>
                    <Field
                      component={Select}
                      name="reportType"
                      type="text"
                      label="ประเภทรายงาน"
                      placeholder="ประเภทรายงาน"
                      options={allFilterReportType}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={Select}
                      name="state"
                      type="text"
                      label="สถานะ"
                      placeholder="สถานะ"
                      options={stateMock}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Field
                      fast={false}
                      component={DatePicker}
                      name="startDate"
                      label="วันที่สร้างรายการ*"
                      placeholder="วันที่สร้างรายการ*"
                      fullWidth
                      maxDate={endDate}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      fast={false}
                      component={DatePicker}
                      name="endDate"
                      label="จนถึงวันที่"
                      placeholder="จนถึงวันที่"
                      fullWidth
                      minDate={startDate}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Box display="flex" flexDirection="row-reverse" mt={4}>
              <Button width={74} type="submit">
                {t(".filter")}
              </Button>
              <Button
                width={74}
                mr={6}
                onClick={handleClearFilter}
                variant="outlined"
              >
                {t(".reset")}
              </Button>
            </Box>
          </Box>
        )}
      </Form>
    </Box>
  );
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      reportType: "",
      state: "",
      startDate: undefined,
      endDate: undefined,
    }),
  }),
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withTranslation({
    prefix: "pages.main.reports.index",
  }),
  withHooks((props: any, hooks: any) => {
    const { useEffect, useCallback, useState, useHandleSubmit } = hooks;
    const { t, values, setFieldValue, resetForm, onSubmitFilter } = props;

    const [showFilter, setShowFilter] = useState(false);
    const [filterCount, setFilterCount] = useState(0);

    const handleClearFilter = useCallback(() => {
      resetForm();
      onSubmitFilter();
    }, [onSubmitFilter, resetForm]);

    const handleShowFilter = useCallback(() => {
      setShowFilter(!showFilter);
    }, [showFilter]);

    useHandleSubmit(() => {
      onSubmitFilter(values);
    });

    useEffect(() => {
      setFilterCount(Object.values(values).filter(Boolean).length);
    }, [setFieldValue, values]);

    return {
      t,
      filterCount,
      showFilter,
      startDate: values.startDate,
      endDate: values.endDate,

      handleShowFilter,
      handleClearFilter,
    };
  })
);

const ReportFilter = enhancer(ReportFilterComponent);

export default ReportFilter;
